import {settings} from "./settings";

export class Lightbox {
    private readonly img: HTMLImageElement;
    private readonly lightBox: HTMLElement;


    constructor(img: HTMLImageElement) {
        this.img = img;
        this.lightBox = document.querySelector(settings.lightbox.lightbox) as HTMLElement;
        this.addEvent();
    }

    addEvent() {
        this.img.addEventListener('click', (e) => {
            e.preventDefault();
            this.lightBox.classList.add(settings.lightbox.lightboxVisible);
            this.lightBox.insertAdjacentHTML('beforeend', `<img src="${this.img.dataset.lightbox}" alt="${this.img.alt}">`);
        });
        this.lightBox.addEventListener('click', (e) => {
            if (!(e.target instanceof HTMLImageElement)) {
                Lightbox.removeLightbox();
            }
        }, true);
    }

    static removeLightbox() {
        document.querySelector(settings.lightbox.lightbox)?.classList.remove(settings.lightbox.lightboxVisible);
        document.querySelector(settings.lightbox.lightbox)?.querySelector('img')?.remove();
    }
}
