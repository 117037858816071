import {Filter} from "./Filter";
import {VideoController} from "./VideoController";
import {settings} from "./settings";
import {SearchForm} from "./SearchForm";
import {GoUpBtn} from "./GoUpBtn";
import {Slider} from "./Slider";
import Swiper from 'swiper';
import {Lightbox} from "./Lightbox";

document.documentElement.classList.add('js');

const url = new URL(window.location.href);

new VideoController();

const filter = new Filter(settings.filter, url);
const search = new SearchForm(settings.searchForm, url);

filter.registerAfterFilter(search.searchWithoutFiltering.bind(search));
search.registerAfterSearch(filter.filterWithoutSearch.bind(filter));

new GoUpBtn(settings.goUpBtn);

new Slider(settings.sliderCat);
new Slider(settings.sliderNews);

// @ts-ignore
(new Swiper(".img_slider", settings.sliderImage))?.init();


document.querySelectorAll(settings.lightbox.imgSelector).forEach((img) => {
    new Lightbox(img as HTMLImageElement);
});

document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape') {
        Lightbox.removeLightbox();
    }
});
